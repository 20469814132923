// Custom API error to throw
function ApiError(message, data, status) {
  let response = null;
  let isObject = false;

  // We are trying to parse response
  try {
    response = JSON.parse(data);
    isObject = true;
  } catch (e) {
    response = data;
  }

  this.response = response;
  this.message = message;
  this.status = status;
  this.toString = function() {
    return `${this.message}\nResponse:\n${isObject ? JSON.stringify(this.response, null, 2) : this.response}`;
  };
}

// API wrapper function
export const fetchResource = (path, userOptions = {}) => {
  // Define default options
  const defaultOptions = {};
  // Define default headers
  const defaultHeaders = {};

  const options = {
    // Merge options
    ...defaultOptions,
    ...userOptions,
    // Merge headers
    headers: {
      ...defaultHeaders,
      ...userOptions.headers,
    },
  };

  // Variable which will be used for storing response
  let response = null;

  return fetch(path, options)
    .then(responseObject => {
      // Saving response for later use in lower scopes
      response = responseObject;
      return response.text();
    })
    .then(textResponse => {
      let parsedResponse;
      try {
        parsedResponse = JSON.parse(textResponse);
      } catch (err) {
        parsedResponse = textResponse;
      }

      // Check for HTTP error codes
      if (response.status < 200 || response.status >= 300) {
        // Throw error
        throw parsedResponse;
      }

      // Request succeeded
      return parsedResponse;
    })
    .catch(error => {
      // Throw custom API error
      // If response exists it means HTTP error occurred
      if (response) {
        throw new ApiError(`Request failed with status ${response.status}.`, error, response.status);
      } else {
        throw new ApiError(error.toString(), null, 'REQUEST_FAILED');
      }
    });
};

// Standardizes Luminate API responses by converting response to an array
export const convertToArray = source => {
  if (source === undefined || source === null) {
    return undefined;
  }
  if (!Array.isArray(source)) {
    return [source];
  }
  return source;
};

// Takes a string and adds wildcards for BB APIs
export const AddWildcards = searchStr => {
  if (!searchStr) return '%%%';

  // split the string by spaces
  const arr = searchStr.split(' ');

  let wildcardString = '%%%';
  arr.forEach(str => {
    wildcardString += `${str}%%%`;
  });

  return wildcardString;
};
