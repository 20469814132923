import { getRegistration, loginTest, logout } from '../services/luminate';
import { getRegistrationForEventAcceptingDonations } from '../helpers/requests/registration';
import { EVENT_TYPE, QUERY_SELECTORS } from '../constants/dom-events';

const {
  user: { consId },
} = luminateProperties;
let activeEvent = null;

/**
 *
 * @param program [eg: STEM]
 * @returns [events][0]
 */
const getEvent = async program => {
  const events = await getRegistrationForEventAcceptingDonations(program);
  return events[0];
};

/**
 * @param {link}, string
 * @returns Boolean
 */
const hasValue = (link, selector) => link.dataset.action.includes(selector);

/** On Document Ready */
document.addEventListener(EVENT_TYPE.DOM_LOAD, () => {
  /** Maintain user session */
  window.setInterval(() => loginTest(), 60000);

  /** constants */
  const { FRC, HIDE_CLASS, LOGIN, LOGOUT, PG_CLASS, PG_PERSONAL } = QUERY_SELECTORS;
  const { CLICK } = EVENT_TYPE;

  /** nav log items */
  const $logLinks = Array.from(document.querySelectorAll(`[data-action="${LOGOUT}"], [data-action="${LOGIN}"]`));
  $logLinks.forEach($link => {
    if (hasValue($link, LOGIN)) {
      const nextUrl = encodeURIComponent(document.location.href);
      $link.href = `${$link.href}${$link.href.includes('?') ? '&' : '?'}nextUrl=${nextUrl}`;
    }

    if (hasValue($link, LOGOUT)) {
      $link.addEventListener(CLICK, event => {
        event.preventDefault();
        logout().then(resp => resp && document.location.reload());
      });
    }
  });

  /** nav frc, class/project page */
  const $pageLinks = Array.from(document.querySelectorAll(`[data-action="${FRC}"], [data-action="${PG_CLASS}"], [data-action="${PG_PERSONAL}"]`));
  $pageLinks.forEach(async $pgLink => {
    /** Event null Case */
    if (!activeEvent) {
      activeEvent = await getEvent($pgLink.dataset.program);
    }

    if (activeEvent) {
      /** Fundraising */
      const isFRCExists = hasValue($pgLink, FRC);
      const { privatePage, teamId } = !isFRCExists && (await getRegistration(activeEvent));
      if (isFRCExists) {
        $pgLink.href = `TRC?pg=center&fr_id=${activeEvent}`;
        $pgLink.classList.remove(HIDE_CLASS);
      }

      /** Class Page */
      if (hasValue($pgLink, PG_CLASS) && privatePage === 'true') {
        $pgLink.href = `TR?pg=team&fr_id=${activeEvent}&team_id=${teamId}`;
        $pgLink.classList.remove(HIDE_CLASS);
      }

      /** Project Page */
      if (hasValue($pgLink, PG_PERSONAL) && privatePage === 'false') {
        $pgLink.href = `TR?px=${consId}&pg=personal&fr_id=${activeEvent}`;
        $pgLink.classList.remove(HIDE_CLASS);
      }
    }
  });
});
