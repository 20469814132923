import { getParticipationTypes, getRegisteredTeamraisers, getRegistration, processRegistration } from '../../services/luminate';

// Get event IDs for this program that the participant is already registered for
export const getParticipantRegistrations = async (program, publicEventType) => {
  const responseData = await getRegisteredTeamraisers(program, publicEventType);

  if (Array.isArray(responseData) && responseData.length > 0) {
    return responseData.map(registration => parseInt(registration.id));
  }

  return [];
};

/**
 * Gets event registration details.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 */
export const fetchRegistration = async ({ eventId }) => {
  const respObj = {
    success: false,
    message: '',
    registration: {},
  };

  try {
    const response = await getRegistration(eventId);
    if (!response.code) {
      respObj.success = true;
      respObj.registration = response;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

/**
 * Gets event participation types.
 * @param {Object} query - The request properties.
 * @param {number} query.eventId - The ID of the event.
 */
export const fetchParticipationTypes = async ({ eventId, promotionCode }) => {
  const respObj = {
    success: false,
    message: '',
    participationTypes: [],
  };

  try {
    const response = await getParticipationTypes({ eventId, promotionCode });
    if (!response.code) {
      respObj.success = true;
      respObj.participationTypes = response.participationType;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

export const userIsRegisteredForEvent = async eventId => {
  const responseData = await getRegistration(eventId);

  return !('code' in responseData);
};

// TODO: merge this with function above
export const getRegistrationForEventAcceptingDonations = async program => {
  const responseData = await getRegisteredTeamraisers(program);

  if (Array.isArray(responseData) && responseData.length > 0) {
    return responseData.filter(registration => registration.accepting_donations === 'true').map(registration => parseInt(registration.id));
  }

  return [];
};

// const values = {
//   consId: 0,
//   acceptWaiver: true,
//   email: '',
//   firstName: '',
//   lastName: '',
//   postalCode: '',
//   country: '',
//   emailOptIn: true,
//   giftNotification: true,
//   fundraisingGoal: 0,
//   screenName: '',
//   teamName: '',
//   teamGoal: 0,
//   isCaptain: true,
//   teamId: 0,
//   questionResponses: [
//  { key: '', value: ''}
// ],
// };

/**
 * Gets event registration details.
 * @param {string} registrationDocument - The XML registration document.
 */
export const submitRegistration = async registrationDocument => {
  const respObj = {
    success: false,
    message: '',
  };

  try {
    const response = await processRegistration(registrationDocument);
    if (!response.code) {
      respObj.success = true;
    } else {
      respObj.message = response.message;
    }

    return respObj;
  } catch (err) {
    return respObj;
  }
};

// Populate a registration document
export const populateRegistrationDocument = (regDocument, values) => {
  const registrationDocument = regDocument;

  for (const [key, value] of Object.entries(values)) {
    if (key !== 'questionResponses') {
      const element = regDocument.querySelector(key);
      if (element && value) {
        element.textContent = typeof value === 'string' ? value.trim() : value;
        element.removeAttribute('xsi:nil');
      }
    }
  }

  if (values && values.questionResponses && values.questionResponses.length > 0) {
    const questions = Array.from(regDocument.querySelectorAll('question'));
    questions.forEach(question => {
      const key = question.querySelector('key').textContent.toLowerCase();
      const foundResponse = values.questionResponses.find(response => response.key.toLowerCase() === key);
      if (foundResponse && foundResponse.value) {
        const questionResponse = question.querySelector('response');
        questionResponse.textContent = typeof foundResponse === 'string' ? foundResponse.value.trim() : foundResponse.value;
        questionResponse.removeAttribute('xsi:nil');
      }
    });
  }

  const serializer = new XMLSerializer();
  return serializer.serializeToString(registrationDocument);
};
