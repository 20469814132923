/** constants */
export const QUERY_SELECTORS = Object.freeze({
  FRC: 'nav-fundraising-center',
  HIDE_CLASS: 'is-hidden',
  LOGIN: 'login',
  LOGOUT: 'logout',
  PG_CLASS: 'nav-class-page',
  PG_PERSONAL: 'nav-personal-page',
});

export const EVENT_TYPE = Object.freeze({
  CLICK: 'click',
  DOM_LOAD: 'DOMContentLoaded',
});
