import { fetchResource, convertToArray } from '../helpers/api';
import { CRTeamraiserAPI, CRConsAPI, CRSurveyAPI, CRDonationAPI } from '../constants/types';

//#region Constants
const API_VERSION = '1.0';
const API_KEY = luminateProperties.apiKey;
const BASE_URL = luminateProperties.secure;
//#endregion

//#region Helpers
// Common Luminate Api parameters
const luminateRequest = async (servlet, formData) => {
  formData.append('v', API_VERSION);
  formData.append('api_key', API_KEY);
  if (!formData.has('response_format')) {
    formData.append('response_format', 'json');
  }
  formData.append('auth', luminateProperties.user.token);
  formData.append('suppress_response_codes', 'true');

  const path = `${BASE_URL}${servlet}`;

  return fetchResource(path, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: formData,
  });
};

const luminateRequestNoHeaders = async (servlet, formData) => {
  formData.append('v', API_VERSION);
  formData.append('api_key', API_KEY);
  if (!formData.has('response_format')) {
    formData.append('response_format', 'json');
  }
  formData.append('auth', luminateProperties.user.token);
  formData.append('suppress_response_codes', 'true');

  const path = `${BASE_URL}${servlet}`;

  return fetchResource(path, {
    method: 'POST',
    credentials: 'include',
    body: formData,
  });
};

// Convert date from TR format to HTML input date format
const convertDate = input => {
  if (input === '') {
    return '';
  }
  const date = new Date(input);
  return (
    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
    '/' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    '/' +
    date.getFullYear()
  );
};
//#endregion

//#region Personal Page Methods
export const updatePersonalMediaLayout = async (eventId, layout) => {
  const formData = new URLSearchParams();
  formData.append('method', 'updatePersonalMediaLayout');
  formData.append('fr_id', eventId);
  formData.append('personal_media_layout', layout);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.updatePersonalMediaLayoutResponse) {
      return responseData.updatePersonalMediaLayoutResponse.personalMediaLayout;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const updatePersonalPageInfo = async ({ eventId, richText, pageTitle }) => {
  const formData = new URLSearchParams();
  formData.append('method', 'updatePersonalPageInfo');
  formData.append('fr_id', eventId);
  if (richText) formData.append('rich_text', richText);
  if (pageTitle) formData.append('page_title', pageTitle.trim());

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.updatePersonalPageResponse) {
      return responseData.updatePersonalPageResponse.success;
    } else {
      return responseData.teamraiserErrorResponse;
    }
  });
};

export const updatePersonalVideoUrl = async (eventId, url) => {
  const formData = new URLSearchParams();
  formData.append('method', 'updatePersonalVideoUrl');
  formData.append('fr_id', eventId);
  formData.append('video_url', url);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.updatePersonalVideoUrlResponse) {
      return responseData.updatePersonalVideoUrlResponse.videoUrl;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const updateShortcut = async (eventId, text) => {
  const formData = new URLSearchParams();
  formData.append('method', 'updateShortcut');
  formData.append('fr_id', eventId);
  formData.append('text', text);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.updateShortcutResponse) {
      return responseData.updateShortcutResponse.shortcutItem;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const uploadPersonalPhoto = async ({ eventId, photo, photo2 }) => {
  const formData = new FormData();
  formData.append('method', 'uploadPersonalPhoto');
  formData.append('fr_id', eventId);
  if (photo) {
    formData.append('graphic_upload_file', photo, 'photo.jpg');
    formData.append('graphic_upload_upload', true);
  }
  if (photo2) {
    formData.append('graphic_upload2_file', photo2, 'photo2.jpg');
    formData.append('graphic_upload2_upload', true);
  }

  return luminateRequestNoHeaders(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.uploadPersonalPhotoResponse) {
      return responseData.uploadPersonalPhotoResponse.photoItem;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const removePersonalPhoto = async ({ eventId, deletePrimaryPhoto, deleteSecondaryPhoto }) => {
  const formData = new URLSearchParams();
  formData.append('method', 'removePersonalPhoto');
  formData.append('fr_id', eventId);
  if (deletePrimaryPhoto) formData.append('graphic_upload_delete', true);
  if (deleteSecondaryPhoto) formData.append('graphic_upload2_delete', true);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.removePersonalPhotoResponse) {
      return responseData.removePersonalPhotoResponse.photoItem;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getPersonalMediaLayout = async (eventId, consId) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getPersonalMediaLayout');
  formData.append('fr_id', eventId);
  formData.append('cons_id', consId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getPersonalMediaLayoutResponse) {
      return responseData.getPersonalMediaLayoutResponse.personalMediaLayout;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getPersonalPageInfo = async (eventId, consId) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getPersonalPageInfo');
  formData.append('fr_id', eventId);
  formData.append('cons_id', consId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getPersonalPageResponse) {
      return responseData.getPersonalPageResponse.personalPage;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getPersonalPageHTML = async (eventId, consId) => {
  const url = `${BASE_URL}TR?px=${consId}&fr_id=${eventId}&pg=personal`;
  return fetchResource(url).then(responseData => {
    if (responseData) {
      const photoUrl = responseData
        .split('personal_page_image_div')[1]
        .split('<img src="')[1]
        .split('" alt="" />')[0];
      return { photoUrl, consId };
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getPersonalVideoUrl = async (eventId, consId) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getPersonalVideoUrl');
  formData.append('fr_id', eventId);
  formData.append('cons_id', consId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getPersonalVideoUrlResponse) {
      return responseData.getPersonalVideoUrlResponse.videoUrl;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getShortcut = async (eventId, consId) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getShortcut');
  formData.append('fr_id', eventId);
  formData.append('cons_id', consId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getShortcutResponse) {
      return responseData.getShortcutResponse.shortcutItem;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getPersonalPhotos = async (eventId, consId) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getPersonalPhotos');
  formData.append('fr_id', eventId);
  formData.append('cons_id', consId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getPersonalPhotosResponse) {
      return convertToArray(responseData.getPersonalPhotosResponse.photoItem);
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getParticipantGifts = async (eventId, consId) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getGifts');
  formData.append('fr_id', eventId);
  formData.append('cons_id', consId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getGiftsResponse) {
      return convertToArray(responseData.getGiftsResponse.gift);
    } else {
      return responseData.errorResponse;
    }
  });
};
//#endregion

//#region Team Page Methods
export const getTeamPageInfo = async (eventId, teamId) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getTeamPageInfo');
  formData.append('fr_id', eventId);
  formData.append('team_id', teamId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getTeamPageResponse) {
      return responseData.getTeamPageResponse.teamPage;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getTeamGifts = async (eventId, consId) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getTeamGifts');
  formData.append('fr_id', eventId);
  formData.append('cons_id', consId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getGiftsResponse) {
      return responseData.getGiftsResponse.totalNumberResults;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getTeamPhoto = async (eventId, consId) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getTeamPhoto');
  formData.append('fr_id', eventId);
  formData.append('cons_id', consId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getTeamPhotoResponse) {
      return convertToArray(responseData.getTeamPhotoResponse.photoItem);
    } else {
      return responseData.errorResponse;
    }
  });
};

export const updateTeamInformation = async ({ eventId, companyId, companyName, divisionName, recruitingGoal, teamGoal, teamName, password }) => {
  const formData = new URLSearchParams();
  formData.append('method', 'updateTeamInformation');
  formData.append('fr_id', eventId);
  if (companyId) formData.append('company_id', companyId);
  if (companyName) formData.append('company_name', companyName);
  if (divisionName) formData.append('division_name', divisionName);
  if (recruitingGoal) formData.append('recruiting_goal', recruitingGoal);
  if (teamGoal) formData.append('team_goal', teamGoal);
  if (teamName) formData.append('team_name', teamName);
  if (password) formData.append('password', password);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.updateTeamInformationResponse) {
      return responseData.updateTeamInformationResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const updateTeamPageInfo = async ({ eventId, richText }) => {
  const formData = new URLSearchParams();
  formData.append('method', 'updateTeamPageInfo');
  formData.append('fr_id', eventId);
  if (richText) formData.append('rich_text', richText);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.updateTeamPageResponse) {
      return responseData.updateTeamPageResponse.success;
    } else {
      return responseData.teamraiserErrorResponse;
    }
  });
};

export const uploadTeamPhoto = async ({ eventId, photo }) => {
  const formData = new FormData();
  formData.append('method', 'uploadTeamPhoto');
  formData.append('fr_id', eventId);
  if (photo) {
    formData.append('graphic_upload_file', photo, 'photo.jpg');
    formData.append('graphic_upload_upload', true);
  }

  return luminateRequestNoHeaders(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.uploadTeamPhotoResponse) {
      return responseData.uploadTeamPhotoResponse.photoItem;
    } else {
      return responseData.errorResponse;
    }
  });
};

//#endregion

//#region Company Page Methods
export const getCompanyPageInfo = async (eventId, consId) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getCompanyPageInfo');
  formData.append('fr_id', eventId);
  formData.append('cons_id', consId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getCompanyPageResponse) {
      return responseData.getCompanyPageResponse.companyPage;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getCompanyPhoto = async (eventId, companyId, consId) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getCompanyPhoto');
  formData.append('fr_id', eventId);
  formData.append('company_id', companyId);
  formData.append('cons_id', consId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getCompanyPhotoResponse) {
      return convertToArray(responseData.getCompanyPhotoResponse.photoItem);
    } else {
      return responseData.errorResponse;
    }
  });
};

export const uploadCompanyPhoto = async ({ companyId, eventId, photo }) => {
  const formData = new FormData();
  formData.append('method', 'uploadCompanyPhoto');
  formData.append('fr_id', eventId);
  formData.append('company_id', companyId);
  if (photo) {
    formData.append('photo_1_file', photo, 'photo.jpg');
    formData.append('photo_1_upload', true);
  }

  return luminateRequestNoHeaders(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.uploadCompanyPhotoResponse) {
      return responseData.uploadCompanyPhotoResponse.photoItem;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const updateCompanyPageInfo = async ({ companyId, eventId, richText }) => {
  const formData = new URLSearchParams();
  formData.append('method', 'updateCompanyPageInfo');
  formData.append('fr_id', eventId);
  formData.append('company_id', companyId);
  if (richText) formData.append('rich_text', richText);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.updateCompanyPageResponse) {
      return responseData.updateCompanyPageResponse.success;
    } else {
      return responseData.teamraiserErrorResponse;
    }
  });
};
//#endregion

//#region Survey Methods
export const getSurveyResponses = async eventId => {
  const formData = new URLSearchParams();
  formData.append('method', 'getSurveyResponses');
  formData.append('fr_id', eventId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getSurveyResponsesResponse) {
      return convertToArray(responseData.getSurveyResponsesResponse.responses);
    } else {
      return responseData.errorResponse;
    }
  });
};

export const updateSurveyResponses = async (eventId, responses) => {
  const formData = new URLSearchParams();
  formData.append('method', 'updateSurveyResponses');
  formData.append('fr_id', eventId);
  formData.append('require_response', 'true');

  responses.forEach(response => {
    const value = response.type === 'date' ? convertDate(response.value) : response.value;
    const identifier = response.questionId || response.key;
    formData.append(identifier, value);
  });

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.updateSurveyResponsesResponse) {
      return convertToArray(responseData.updateSurveyResponsesResponse);
    } else {
      return responseData.errorResponse;
    }
  });
};

export const updateSurveyBackgroundResponses = async (eventId, responses) => {
  const formData = new URLSearchParams();
  formData.append('method', 'updateSurveyResponses');
  formData.append('fr_id', eventId);
  formData.append('require_response', 'true');

  responses.forEach(response => {
    const value = response.type === 'date' ? convertDate(response.value) : response.value;
    const identifier = response.questionId || response.key;
    formData.append(identifier, value);

    // This value is required in order to update the Background
    // Reference JIRA ticket# ALSACDIY-352
    formData.append('question_key_QKEY_CPA_AGE', 'true');
  });

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.updateSurveyResponsesResponse) {
      return convertToArray(responseData.updateSurveyResponsesResponse);
    } else {
      return responseData.errorResponse;
    }
  });
};
//#endregion

//#region Team Methods
export const getTeam = async (frId, teamId) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getTeam');
  formData.append('fr_id', frId);
  formData.append('team_id', teamId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getTeamResponse) {
      return responseData.getTeamResponse.team;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getTeamsByInfo = async searchParams => {
  const formData = new URLSearchParams();
  formData.append('method', 'getTeamsByInfo');

  if (searchParams.teamId) {
    formData.append('team_id', searchParams.teamId);
  }
  if (searchParams.eventId) {
    formData.append('fr_id', searchParams.eventId);
  }
  if (searchParams.companyId) {
    formData.append('team_company_id', searchParams.companyId);
  }
  if (searchParams.eventType) {
    formData.append('event_type', searchParams.eventType);
  }
  if ('fullSearch' in searchParams) {
    formData.append('full_search', searchParams.fullSearch);
  }
  if (searchParams.listPageSize) {
    formData.append('list_page_size', searchParams.listPageSize);
  }
  if (searchParams.listPageOffset) {
    formData.append('list_page_offset', searchParams.listPageOffset);
  }
  if (searchParams.listSortColumn) {
    formData.append('list_sort_column', searchParams.listSortColumn);
  }
  if ('listAscending' in searchParams) {
    formData.append('list_ascending', searchParams.listAscending);
  }
  if (searchParams.firstName) {
    formData.append('first_name', searchParams.firstName);
  }
  if (searchParams.lastName) {
    formData.append('last_name', searchParams.lastName);
  }
  if (searchParams.fundraiserName) {
    formData.append('screenname', searchParams.fundraiserName);
  }
  if (searchParams.teamName) {
    formData.append('team_name', searchParams.teamName);
  }
  if (searchParams.publicEventType) {
    formData.append('public_event_type', searchParams.publicEventType);
  }

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getTeamSearchByInfoResponse) {
      responseData.getTeamSearchByInfoResponse.team = convertToArray(responseData.getTeamSearchByInfoResponse.team);
      return responseData.getTeamSearchByInfoResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getTeamCaptains = async (eventId, teamId) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getTeamCaptains');
  formData.append('fr_id', eventId);
  formData.append('team_id', teamId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getTeamCaptainsResponse) {
      return responseData.getTeamCaptainsResponse.captain;
    } else if (responseData.errorResponse) {
      return responseData.errorResponse;
    } else {
      return false;
    }
  });
};

export const joinTeam = async (eventId, teamId) => {
  const formData = new URLSearchParams();
  formData.append('method', 'joinTeam');
  formData.append('fr_id', eventId);
  formData.append('team_id', teamId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.joinTeamResponse && responseData.joinTeamResponse.success) {
      if (responseData.joinTeamResponse.success.content === 'true') {
        return true;
      } else {
        return false;
      }
    } else if (responseData.errorResponse) {
      return responseData.errorResponse;
    } else {
      return false;
    }
  });
};

export const leaveTeam = async eventId => {
  const formData = new URLSearchParams();
  formData.append('method', 'leaveTeam');
  formData.append('fr_id', eventId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.leaveTeamResponse) {
      if (responseData.leaveTeamResponse.success === 'true') {
        return true;
      } else {
        return false;
      }
    } else if (responseData.errorResponse) {
      return responseData.errorResponse;
    } else {
      return false;
    }
  });
};

/**
 * Returns team members for a specific team and event.
 * @param {number} eventId - The ID of the event.
 * @param {number} teamId - The ID of the team.
 */
export const getTeamMembers = async (eventId, teamId) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getTeamMembers');
  formData.append('fr_id', eventId);
  formData.append('team_id', teamId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getTeamMembersResponse) {
      responseData.getTeamMembersResponse.member = convertToArray(responseData.getTeamMembersResponse.member);
      return responseData.getTeamMembersResponse;
    } else if (responseData.errorResponse) {
      return responseData.errorResponse;
    } else {
      return false;
    }
  });
};
//#endregion

//#region Company Methods
export const getCompaniesByInfo = async searchParams => {
  const formData = new URLSearchParams();
  formData.append('method', 'getCompaniesByInfo');

  if (searchParams.eventType) {
    formData.append('event_type', searchParams.eventType);
  }
  if (searchParams.companyId) {
    formData.append('company_id', searchParams.companyId);
  }
  if (searchParams.companyName) {
    formData.append('company_name', searchParams.companyName);
  }
  if (searchParams.frId) {
    formData.append('fr_id', searchParams.frId);
  }
  if (searchParams.listPageSize) {
    formData.append('list_page_size', searchParams.listPageSize);
  }
  if (searchParams.listPageOffset) {
    formData.append('list_page_offset', searchParams.listPageOffset);
  }
  if ('listAscending' in searchParams) {
    formData.append('list_ascending', searchParams.listAscending);
  }
  if (searchParams.listSortColumn) {
    formData.append('list_sort_column', searchParams.listSortColumn);
  }
  if (searchParams.listFilterColumn) {
    formData.append('list_filter_column', searchParams.listFilterColumn);
  }
  if (searchParams.listFilterText) {
    formData.append('list_filter_text', searchParams.listFilterText);
  }

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getCompaniesResponse) {
      responseData.getCompaniesResponse.company = convertToArray(responseData.getCompaniesResponse.company);
      return responseData.getCompaniesResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};

/**
 * Returns local companies for an event.
 * @param {number} eventId - The ID of the event.
 */
export const getCompanyList = async eventId => {
  const formData = new URLSearchParams();
  formData.append('method', 'getCompanyList');
  formData.append('fr_id', eventId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getCompanyListResponse) {
      return convertToArray(responseData.getCompanyListResponse.companyItem);
    } else if (responseData.errorResponse) {
      return responseData.errorResponse;
    } else {
      return false;
    }
  });
};

export const getCompanyTeams = async ({ eventId, companyId }) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getCompanyTeams');
  formData.append('fr_id', eventId);
  formData.append('company_id', companyId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getCompanyTeamsResponse) {
      return convertToArray(responseData.getCompanyTeamsResponse.companyTeam);
    } else if (responseData.errorResponse) {
      return responseData.errorResponse;
    } else {
      return false;
    }
  });
};
//#endregion

//#region Registration Methods
export const processRegistration = async registration => {
  const formData = new URLSearchParams();
  formData.append('method', 'processRegistration');
  formData.append('registration_document', registration);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.processRegistrationResponse) {
      return responseData.processRegistrationResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const updateRegistration = async ({ eventId, goal, screenName }) => {
  const formData = new URLSearchParams();
  formData.append('method', 'updateRegistration');
  formData.append('fr_id', eventId);
  if (goal) formData.append('goal', goal);
  if (screenName) formData.append('screenname', screenName);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.updateRegistrationResponse) {
      return responseData.updateRegistrationResponse.success;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getRegistration = async eventId => {
  const formData = new URLSearchParams();
  formData.append('method', 'getRegistration');
  formData.append('fr_id', eventId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getRegistrationResponse) {
      return responseData.getRegistrationResponse.registration;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getRegistrationDocument = async (eventId, partTypeId) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getRegistrationDocument');
  formData.append('fr_id', eventId);
  formData.append('participation_id', partTypeId);
  formData.append('response_format', 'xml');

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => new window.DOMParser().parseFromString(responseData, 'text/xml'));
};

export const getParticipantProgress = async (eventId, consId) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getParticipantProgress');
  formData.append('cons_id', consId);
  formData.append('fr_id', eventId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getParticipantProgressResponse) {
      return responseData.getParticipantProgressResponse;
    } else if (responseData.errorResponse) {
      return responseData.errorResponse;
    } else {
      return false;
    }
  });
};
//#endregion

//#region Event Methods
export const getParticipationTypes = async ({ eventId, promoCode }) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getParticipationTypes');
  formData.append('fr_id', eventId);
  if (promoCode) formData.append('promotion_code', promoCode);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getParticipationTypesResponse) {
      if (promoCode) responseData.getParticipationTypesResponse.promotionCode = promoCode;
      responseData.getParticipationTypesResponse.participationType = convertToArray(responseData.getParticipationTypesResponse.participationType);
      return responseData.getParticipationTypesResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getParticipants = async searchParams => {
  const formData = new URLSearchParams();
  formData.append('method', 'getParticipants');

  if (searchParams.eventType) {
    formData.append('event_type', searchParams.eventType);
  }
  if (searchParams.eventId) {
    formData.append('fr_id', searchParams.eventId);
  }
  if (searchParams.pageSize) {
    formData.append('list_page_size', searchParams.pageSize);
  }
  if (searchParams.listPageOffset) {
    formData.append('list_page_offset', searchParams.listPageOffset);
  }
  if ('listAscending' in searchParams) {
    formData.append('list_ascending', searchParams.listAscending);
  }
  if (searchParams.listSortColumn) {
    formData.append('list_sort_column', searchParams.listSortColumn);
  }
  if (searchParams.listFilterColumn) {
    formData.append('list_filter_column', searchParams.listFilterColumn);
  }
  if (searchParams.listFilterText) {
    formData.append('list_filter_text', searchParams.listFilterText);
  }
  if (searchParams.firstName) {
    formData.append('first_name', searchParams.firstName);
  }
  if (searchParams.lastName) {
    formData.append('last_name', searchParams.lastName);
  }
  if (searchParams.screenName) {
    formData.append('screenname', searchParams.screenName);
  }
  if (searchParams.teamName) {
    formData.append('team_name', searchParams.teamName);
  }
  if (searchParams.fullSearch) {
    formData.append('full_search', searchParams.fullSearch);
  }
  if (searchParams.publicEventType) {
    formData.append('public_event_type', searchParams.publicEventType);
  }

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getParticipantsResponse) {
      responseData.getParticipantsResponse.participant = convertToArray(responseData.getParticipantsResponse.participant);
      return responseData.getParticipantsResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getRegisteredTeamraisers = async (eventType, publicEventType = '', consId = '') => {
  const formData = new URLSearchParams();
  formData.append('method', 'getRegisteredTeamraisers');
  formData.append('public_event_type', publicEventType);
  formData.append('event_type', eventType);
  if (consId) {
    formData.append('cons_id', consId);
  }

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    // The API response will be this error if the participant has no registrations for this event type
    if (responseData.errorResponse && responseData.errorResponse.code === '2603') {
      return [];
    }
    if (responseData.getRegisteredTeamraisersResponse) {
      return convertToArray(responseData.getRegisteredTeamraisersResponse.teamraiser);
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getTeamraisersByInfo = async (eventType, name = '%%%', publicEventType = '') => {
  const formData = new URLSearchParams();
  formData.append('method', 'getTeamraisersByInfo');
  formData.append('name', name);
  formData.append('event_type', eventType);
  formData.append('public_event_type', publicEventType);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    console.log('response get By Info, ', responseData);
    if (responseData.getTeamraisersResponse) {
      return convertToArray(responseData.getTeamraisersResponse.teamraiser);
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getTeamraisersByInfoEventName = async (eventName, eventType, publicEventType) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getTeamraisersByInfo');
  formData.append('name', eventName);
  formData.append('event_type', eventType);
  formData.append('public_event_type', publicEventType);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    console.log('response get By Info,', responseData);
    if (responseData.getTeamraisersResponse) {
      return convertToArray(responseData.getTeamraisersResponse.teamraiser);
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getTeamraisersByInfoState = async searchParams => {
  const formData = new URLSearchParams();
  formData.append('method', 'getTeamraisersByInfo');
  if (searchParams.eventType) {
    formData.append('event_type', searchParams.eventType);
  }
  if (searchParams.publicEventType) {
    formData.append('public_event_type', searchParams.publicEventType);
  }
  if (searchParams.listPageSize) {
    formData.append('list_page_size', searchParams.listPageSize);
  }
  if (searchParams.listPageOffset) {
    formData.append('list_page_offset', searchParams.listPageOffset);
  }
  if ('listAscending' in searchParams) {
    formData.append('list_ascending', searchParams.listAscending);
  }
  if (searchParams.listSortColumn) {
    formData.append('list_sort_column', searchParams.listSortColumn);
  }
  if (searchParams.state) {
    formData.append('state', searchParams.state);
  }

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    console.log('response get By Info,', responseData);
    if (responseData.getTeamraisersResponse) {
      responseData.getTeamraisersResponse.teamraiser = convertToArray(responseData.getTeamraisersResponse.teamraiser);
      return responseData.getTeamraisersResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};

// export const getTeamraisersByDistance = async (eventType, name = '%%%', publicEventType = '') => {
export const getTeamraisersByDistance = async searchParams => {
  const formData = new URLSearchParams();
  formData.append('method', 'getTeamraisersByDistance');
  if (searchParams.eventType) {
    formData.append('event_type', searchParams.eventType);
  }
  if (searchParams.startingPostal) {
    formData.append('starting_postal', searchParams.startingPostal);
  }
  if (searchParams.searchDistance) {
    formData.append('search_distance', searchParams.searchDistance);
  }
  if (searchParams.distanceUnits) {
    formData.append('distance_units', searchParams.distanceUnits);
  }
  if (searchParams.listPageSize) {
    formData.append('list_page_size', searchParams.listPageSize);
  }
  if (searchParams.listPageOffset) {
    formData.append('list_page_offset', searchParams.listPageOffset);
  }
  if ('listAscending' in searchParams) {
    formData.append('list_ascending', searchParams.listAscending);
  }
  if (searchParams.listSortColumn) {
    formData.append('list_sort_column', searchParams.listSortColumn);
  }
  if (searchParams.publicEventType) {
    formData.append('public_event_type', searchParams.publicEventType);
  }

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    console.log('responseData in luminate.js event zip search', responseData);
    if (responseData.getTeamraisersResponse) {
      responseData.getTeamraisersResponse.teamraiser = convertToArray(responseData.getTeamraisersResponse.teamraiser);
      return responseData.getTeamraisersResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};

export const getTeamraiserConfig = async eventId => {
  const formData = new URLSearchParams();
  formData.append('method', 'getTeamraiserConfig');
  formData.append('fr_id', eventId);

  return luminateRequest(CRTeamraiserAPI, formData).then(responseData => {
    if (responseData.getTeamraiserConfigResponse) {
      return responseData.getTeamraiserConfigResponse.teamraiserConfig;
    } else {
      return responseData.errorResponse;
    }
  });
};
//#endregion

//#region Sign-On Methods
/**
 * Login method.
 * @param {Object} requestParams - The search parameters.
 * @param {boolean} requestParams.sendUsername - Send the userName/password reminder.
 * @param {string} requestParams.email - The email to send the user name/password reminder to.
 * @param {boolean} requestParams.rememberMe - Retain the session on the next page visit.
 * @param {string} requestParams.password - The user's password.
 * @param {string} requestParams.userName - The user's user name.
 */
export const login = async requestParams => {
  const formData = new URLSearchParams();
  formData.append('method', 'login');

  if (requestParams.password) formData.append('password', requestParams.password);
  if ('sendUsername' in requestParams) formData.append('send_user_name', requestParams.sendUsername);
  if ('rememberMe' in requestParams) formData.append('remember_me', requestParams.rememberMe);
  if (requestParams.email) formData.append('email', requestParams.email);
  if (requestParams.userName) formData.append('user_name', requestParams.userName);

  return luminateRequest(CRConsAPI, formData).then(responseData => {
    if (responseData.loginResponse) {
      return responseData.loginResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};

/**
 * Logout method.
 */
export const logout = async () => {
  const formData = new URLSearchParams();
  formData.append('method', 'logout');

  return luminateRequest(CRConsAPI, formData).then(responseData => {
    if (responseData.loginResponse) {
      return true;
    } else {
      return false;
    }
  });
};

/**
 * LoginTest method.
 */
export const loginTest = async () => {
  const formData = new URLSearchParams();
  formData.append('method', 'loginTest');

  return luminateRequest(CRConsAPI, formData).then(responseData => {
    if (responseData.loginResponse) {
      return responseData.loginResponse;
    } else {
      return false;
    }
  });
};

/**
 * GetLoginUrl method.
 */
export const getLoginUrl = async () => {
  const formData = new URLSearchParams();
  formData.append('method', 'getLoginUrl');

  return luminateRequest(CRConsAPI, formData).then(responseData => {
    if (responseData.getLoginUrlResponse) {
      return responseData.getLoginUrlResponse;
    } else {
      return false;
    }
  });
};

/**
 * changePassword method.
 * @param {Object} requestParams - The parameters.
 * @param {string} requestParams.oldPassword - The existing password.
 * @param {string} requestParams.newPassword - The new password.
 * @param {string} requestParams.reminderHint - The reminder for the password
 */
export const changePassword = async requestParams => {
  const formData = new URLSearchParams();
  formData.append('method', 'changePassword');

  formData.append('old_password', requestParams.oldPassword);
  formData.append('user_password', requestParams.newPassword);
  formData.append('retype_password', requestParams.newPassword);
  formData.append('reminder_hint', requestParams.reminderHint);

  return luminateRequest(CRConsAPI, formData).then(responseData => {
    if (responseData.updateConsResponse) {
      return true;
    } else {
      return false;
    }
  });
};
//#endregion

//#region Constituent Methods
/**
 * log interaction method.
 * @param {Object} query - The query.
 * @param {number} query.typeId - The interaction type ID.
 * @param {string} query.subject - The interaction subject.
 * @param {string} query.body - The interaction body.
 */
export const logInteraction = async query => {
  const formData = new URLSearchParams();
  formData.append('method', 'logInteraction');
  if (query.typeId) formData.append('interaction_type_id', query.typeId);
  if (query.subject) formData.append('interaction_subject', query.subject);
  if (query.body) formData.append('interaction_body', query.body);

  return luminateRequest(CRConsAPI, formData).then(responseData => {
    if (responseData.updateConsResponse) {
      return responseData.updateConsResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};

/**
 * update interaction method.
 * @param {Object} query - The query.
 * @param {number} query.id - The interaction ID.
 * @param {number} query.typeId - The interaction type ID.
 * @param {string} query.subject - The interaction subject.
 * @param {string} query.body - The interaction body.
 */
export const updateInteraction = async query => {
  const formData = new URLSearchParams();
  formData.append('method', 'updateInteraction');
  if (query.id) formData.append('interaction_id', query.id);
  if (query.typeId) formData.append('interaction_type_id', query.typeId);
  if (query.subject) formData.append('interaction_subject', query.subject);
  if (query.body) formData.append('interaction_body', query.body);

  return luminateRequest(CRConsAPI, formData).then(responseData => {
    if (responseData.updateConsResponse) {
      return responseData.updateConsResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};

/**
 * get user interactions method.
 * @param {Object} query - The query.
 * @param {string} query.subject - The interaction subject.
 * @param {number} query.typeId - The interaction type ID.
 * @param {number} query.pageSize - The response page size.
 */
export const getUserInteractions = async query => {
  const formData = new URLSearchParams();
  formData.append('method', 'getUserInteractions');
  if (query.subject) formData.append('interaction_subject', query.subject);
  if (query.typeId) formData.append('interaction_type_id', query.typeId);
  if (query.pageSize) formData.append('list_page_size', query.pageSize);

  return luminateRequest(CRConsAPI, formData).then(responseData => {
    if (responseData.getUserInteractionsResponse) {
      responseData.getUserInteractionsResponse.interaction = convertToArray(responseData.getUserInteractionsResponse.interaction) || [];
      return responseData.getUserInteractionsResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};

/**
 * create constituent method.
 * @param {Object} user - The user.
 * @param {string} user.firstName - The first name.
 * @param {string} user.lastName - The last name.
 * @param {string} user.email - The email address.
 * @param {string} user.userName - The account user name.
 * @param {string} user.country - The address country.
 * @param {string} user.postalCode - The address postal code.
 * @param {string} user.password - The account password.
 * @param {string} user.emailOptIn - If the user is opted-in to email.
 */
export const create = async user => {
  const formData = new URLSearchParams();
  formData.append('method', 'create');
  formData.append('name.first', user.firstName);
  formData.append('name.last', user.lastName);
  formData.append('user_password', user.password);
  if (user.email) formData.append('primary_email', user.email);
  if (user.userName) formData.append('user_name', user.userName);
  if (user.country) formData.append('primary_address.country', user.country);
  if (user.postalCode) formData.append('primary_address.zip', user.postalCode);
  if ('emailOptIn' in user) formData.append('email.accepts_email', user.emailOptIn);

  return luminateRequest(CRConsAPI, formData).then(responseData => {
    if (responseData.createConsResponse) {
      return responseData.createConsResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};

/**
 * isEmailValid method.
 * @param {string} email - The email address.
 */
export const isEmailValid = async email => {
  const formData = new URLSearchParams();
  formData.append('method', 'isEmailValid');
  formData.append('email', email);

  return luminateRequest(CRConsAPI, formData).then(responseData => {
    if (responseData.isEmailValidResponse) {
      return responseData.isEmailValidResponse.valid === 'true';
    } else {
      return false;
    }
  });
};

/**
 * Get constituent method.
 * @param {string} fields - A comma delimited list of fields to include in the response.
 */
export const getUser = async fields => {
  const formData = new URLSearchParams();
  formData.append('method', 'getUser');
  if (fields) formData.append('fields', fields);

  return luminateRequest(CRConsAPI, formData).then(responseData => {
    if (responseData.getConsResponse) {
      return responseData.getConsResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};

/**
 * isUsernameAvailable method.
 * Will return false for any userName in email format.
 * @param {string} userName - The user name to check.
 */
export const isUsernameAvailable = async userName => {
  const formData = new URLSearchParams();
  formData.append('method', 'isUserNameAvailable');
  formData.append('user_name', userName);

  return luminateRequest(CRConsAPI, formData).then(responseData => {
    if (responseData.isUserNameAvailableResponse) {
      return responseData.isUserNameAvailableResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};
//#endregion

//#region Survey Methods
/**
 * submitSurvey method.
 * @param {object} requestParams - The request parameters
 * @param {number} requestParams.surveyId - The ID of the survey
 * @param {string} requestParams.email - The constituent's email
 * @param {boolean} requestParams.emailOptIn - The constituent elects to receive email
 * @param {Array.<Object.<string, string>>} requestParams.questions - Array of question objects
 */
export const submitSurvey = async requestParams => {
  const formData = new URLSearchParams();
  formData.append('method', 'submitSurvey');
  formData.append('survey_id', requestParams.surveyId);
  if (requestParams.email) formData.append('cons_email', requestParams.email);
  if (requestParams.emailOptIn) formData.append('cons_email_opt_in', requestParams.emailOptIn);
  if (Array.isArray(requestParams.questions)) {
    requestParams.questions.forEach(question => {
      formData.append(question.id, question.response);
    });
  }

  return luminateRequest(CRSurveyAPI, formData).then(responseData => {
    if (responseData.submitSurveyResponse) {
      return responseData.submitSurveyResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};

/**
 * getSurvey method.
 * @param {object} requestParams - The request parameters
 * @param {number} requestParams.surveyId - The ID of the survey
 * @param {string} requestParams.email - The constituent's email
 * @param {boolean} requestParams.emailOptIn - The constituent elects to receive email
 * @param {Array.<Object.<string, string>>} requestParams.questions - Array of question objects
 */
export const getSurvey = async surveyId => {
  const formData = new URLSearchParams();
  formData.append('method', 'getSurvey');
  formData.append('survey_id', surveyId);

  return luminateRequest(CRSurveyAPI, formData).then(responseData => {
    if (responseData.getSurveyResponse) {
      return responseData.getSurveyResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};
//#endregion

//#region Donation Methods
/**
 * getDonationFormInfo method.
 * @param {object} requestParams - The request parameters
 * @param {number} requestParams.eventId - The ID of the form
 * @param {number} requestParams.formId - The ID of the event
 */
export const getDonationFormInfo = ({ eventId, formId }) => {
  const formData = new URLSearchParams();
  formData.append('method', 'getDonationFormInfo');
  formData.append('form_id', formId);
  formData.append('fr_id', eventId);
  formData.append('include_custom_elements', true);

  return luminateRequest(CRDonationAPI, formData).then(responseData => {
    if (responseData.getDonationFormInfoResponse) {
      return responseData.getDonationFormInfoResponse;
    } else {
      return responseData.errorResponse;
    }
  });
};
//#endregion

//#region Roll Your Own API Methods
export const getPersonalEventInfo = async (eventId, consId) => {
  const path = `${luminateProperties.secure}SPageServer?pagename=api_event_location&pgwrap=n&fr_id=${eventId}&cons_id=${consId}`;
  return await fetchResource(path);
};

export const getTopDonors = async (eventId, consId, limit) => {
  const path = `${luminateProperties.secure}SPageServer?pagename=api_top_donors&pgwrap=n&fr_id=${eventId}&cons_id=${consId}&limit=${limit || 5}`;
  const response = await fetchResource(path);
  const trimmedResponse = response.trim().replace(/&nbsp;/g, ' ');
  const xmlDoc = new DOMParser().parseFromString(trimmedResponse, 'text/xml');
  const liArray = Array.from(xmlDoc.querySelectorAll('li'));

  const donorArray = [];

  liArray.forEach(li => {
    const textArray = li.textContent.split(' - ');
    donorArray.push({
      name: textArray[0].trim(),
      amount: textArray.length > 1 ? textArray[1].trim() : '',
    });
  });

  return donorArray;
};

export const getTeamCaptainSurveyResponses = async (eventId, consId) => {
  let url = `${BASE_URL}SPageServer?pagename=diy_get_survey_responses&pgwrap=n`;
  url += `&fr_id=${eventId}`;
  url += `&px=${consId}`;
  return await fetchResource(url)
    .then(res => res)
    .catch(err => {
      throw err;
    });
};

export const getParticipantAchievements = async (eventId, consId) => {
  let url = `${BASE_URL}SPageServer?pagename=diy_get_participant_achievements&pgwrap=n`;
  url += `&fr_id=${eventId}`;
  url += `&cons_id=${consId}`;

  return await fetchResource(url)
    .then(res => res)
    .catch(err => {
      throw err;
    });
};

export const getBackgroundImagesArray = async eventId => {
  const url = `${BASE_URL}SPageServer?pagename=diy_get_page_bg_images&pgwrap=n&fr_id=${eventId}`;

  return await fetchResource(url)
    .then(res => res)
    .catch(err => {
      throw err;
    });
};

export const getBackgroundImagesArrayHeroes = async eventId => {
  const url = `${BASE_URL}SPageServer?pagename=diy_heroes_get_page_bg_images&pgwrap=n&fr_id=${eventId}`;

  return await fetchResource(url)
    .then(res => res)
    .catch(err => {
      throw err;
    });
};
//#endregion

//#region FTP Methods

export const getParticipationTypeMap = async fileName => {
  const path = `${luminateProperties.assetsPath}config/${luminateProperties.environment}/${fileName}`;
  return await fetchResource(path);
};

//#endregion
